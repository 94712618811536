import React from "react";

import BusinessRetain from "../components/Home/HomeRetain";

const Business = () => {
  return (
    <div className="business head-bg py-75">
      <BusinessRetain />
    </div>
  );
};

export default Business;
