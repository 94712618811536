import React, { Component } from "react";
import LogoElement from "../../images/home/customers/logo-element-orange.svg";
import Currybox from "../../images/home/customers/currybox3.png";
import Alfredo from "../../images/home/customers/alfredo3.png";
import Lila from "../../images/home/customers/lilla3.png";
import Sushi from "../../images/home/customers/sushi3.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomerSlick from "react-slick";

import Pulse from "react-reveal/Pulse";
import Bounce from "react-reveal/Bounce";

export default class CustomerSlicks extends Component {
  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },

        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="customer-section" style={{marginBottom: window.innerWidth < 767 ? '75px' : '0px'}}>
        <section className="container customer-slick">
          <Pulse>
            <div className="container-heading">
              <img
                className="container-heading__image"
                src={LogoElement}
                alt="bubble"
              />
              <h1 className="container-heading__title">Our Customers</h1>
            </div>
          </Pulse>
          <div className="container-body pt-50">
            <Bounce>
              <CustomerSlick {...settings}>
                <div className="customerBox">
                  <img className="mobile-business-img" src={Currybox} />
                  {/* <h6 className="mb-4">Curry Box</h6> */}
                </div>
                <div className="customerBox">
                  <img className="mobile-business-img" src={Alfredo} />
                  {/* <h6 className="mb-4">Alfredo Pasta Fresca</h6> */}
                </div>
                <div className="customerBox">
                  <img className="mobile-business-img" src={Sushi} />
                  {/* <h6 className="mb-4">Sushi LP Herlev</h6> */}
                </div>
                <div className="customerBox">
                  <img className="mobile-business-img" src={Lila} />
                  {/* <h6 className="mb-4">lilla Husman</h6> */}
                </div>
              </CustomerSlick>
            </Bounce>
          </div>
        </section>
      </div>
    );
  }
}
