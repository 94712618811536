export const menuDataHome = [
    {id: 'home'},
    {title: 'For Business', link: 'business'},
    {title: 'About Us', link: '/aboutus'},
    {title: 'Contact', link: 'contactus'},
]

export const menuDataOther = [
    {id: ''},
    {title: 'For Business', link: '/'},
    {title: 'About Us', link: '/aboutus'},
    {title: 'Contact', link: '/'},
]