import React from "react";
import LogoElement from "../../images/home/benefits-images/rectangle-30.svg";

import avatar from "../../images/aboutus/avatar.svg";
import Santosh from "../../images/aboutus/Santosh-Kumar.jpg";
// import Georgia from "../../images/aboutus/Georgia-Santucci.jpg";
import Fredrik from "../../images/aboutus/Fredrik-Jonsson.jpg";
// import Thomas from "../../images/aboutus/Thomas-Knudssøn.jpg";
import Sudip from "../../images/aboutus/Sudip-Samanta.jpg";
import Ellie from "../../images/aboutus/ellie-roberts.jpeg";
import Aditi from "../../images/aboutus/aditi.jpeg";
import Kiara from "../../images/aboutus/kiara.jpeg";
import Thomas from "../../images/aboutus/thomas.jpeg";
import Olesen from "../../images/aboutus/olesen.jpeg";
import Abdel from "../../images/aboutus/abdel.jpeg";
import Tapas from "../../images/aboutus/Tapas-Nayak.jpg";
// import Sofia from "../../images/aboutus/Sofia-Gudmundsson.jpg";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const OurTeam = () => {
  return (
    <div className="our-team">
      <section className="container our-teams">
        <Zoom>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={LogoElement}
              alt="bubble"
            />
            <h1 className="container-heading__title">Our Team</h1>
          </div>
        </Zoom>
        <div className="container-body ">
          <div className="container-body__card w-65 py-100 m-auto">
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Santosh}
                  alt="Santosh Kumar"
                />
                <h3 style={{fontWeight:'500'}}>Santosh Kumar</h3>
                <p>
                  Founder & CEO
              </p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Abdel}
                  alt="abdel"
                />
                <h3 style={{fontWeight:'500'}}>Abdel Ajouz</h3>
                <p>Head of Sales-Sweden</p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Sudip}
                  alt="sudip"
                />
                <h3 style={{fontWeight:'500'}}>Sudip Samanta</h3>
                <p>Senior Backend Developer</p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Ellie}
                  alt="ellie"
                />
                <h3 style={{fontWeight:'500'}}>Ellie Roberts</h3>
                <p>Sales Intern</p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Kiara}
                  alt="aditi"
                />
                <h3 style={{fontWeight:'500'}}>Aditi</h3>
                <p>UI/UX</p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Aditi}
                  alt="Klara"
                />
                <h3 style={{fontWeight:'500'}}>Klara</h3>
                <p>Sales Intern</p>
              </div>
            </Fade>
          </div>
        </div>
        <Zoom>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={LogoElement}
              alt="bubble"
            />
            <h1 className="container-heading__title">Advisors</h1>
          </div>
        </Zoom>
        <div className="container-body ">
          <div className="container-body__card w-65 py-100 m-auto">
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Thomas}
                  alt="Thomas knudsson"
                />
                <h3 style={{fontWeight:'500'}}>Thomas Knudsson</h3>
                <p>
                Sales & Growth
              </p>
              </div>
            </Fade>
            <Fade left>
              <div className="container-body__card-avatar w-33 p-25 text-center">
                <img
                  className="container-avatar__image"
                  src={Olesen}
                  alt="Thomas olesen"
                />
                <h3 style={{fontWeight:'500'}}>Thomas Olesen</h3>
                <p>Service Design</p>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurTeam;
