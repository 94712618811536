import React from "react";
import Bubble from "../../images/home/bubble1.png";
import Icon1 from "../../images/home/retain1.svg";
import Icon2 from "../../images/home/retain2.svg";
import Icon3 from "../../images/home/retain3.svg";
import Icon4 from "../../images/home/retain4.svg";
import { ReactComponent as homeBg1 } from "../../images/home/home-bg1.svg";
// import "../styles/EngageCustomer.css";
import styled, { keyframes } from "styled-components";
import Pulse from "react-reveal/Pulse";
import Fade from "react-reveal/Fade";

const fade = keyframes`
from {
  transform: translateY(50px);
}
to {
  transform: translateY(0px);
}`;

const dash = keyframes`
	from {
		stroke-dashoffset: 100;
	}
	to {
		stroke-dashoffset: 0;
	}
}`;

const BgAnimation = styled(homeBg1)`
  .cricle-bg {
    animation: ${fade} infinite alternate 3s ease;
  }
`;

const HomeRetain = () => {
  return (
    <div className="home-retain" id="business">
      {/* <BgAnimation className="bg-image" /> */}
      <div className="container-body_main" style={{position:'static'}}>
        <section className="container" style={{marginBottom: window.innerWidth < 767 ? '75px' : '0px'}}>
          <Pulse>
            <div className="container-heading">
              <img
                className="container-heading__image"
                src={Bubble}
                alt="bubble"
              />
              <h1 className="container-heading__title">
                Seamlessly engage and retain customers
              </h1>
            </div>
          </Pulse>
          <div className="container-body" style={{marginTop:'50px'}}>
            <div className="container-body__card" style={{paddingTop:'10px'}}>
              <Fade left>
                <div className="container-body__card-left text-right px-75">
                  <img className="icons" src={Icon1} alt="icon1" />
                </div>
              </Fade>
              <Fade right>
                <div className="container-body__card-right text-left">
                  <h2 className="container-body__card-title">
                  Loyalty and Rewards in Real Time
                  </h2>
                  <p>
                  No need for loyalty cards, coupons or offer codes, just direct engagement through the PayTack app
                  </p>
				   {/* <p>
                  - No need for old-fashioned loyalty cards, coupons, or offer codes. Customers are digitally engaged and rewarded
                  </p> */}
                </div>
              </Fade>
            </div>
            <div className="container-body__card grid-order__change" style={{paddingTop:'10px'}}>
              <Fade left>
                <div className="container-body__card-left px-75 text-left">
                  <h2 className="container-body__card-title">
                  The Most Affordable Solution
                  </h2>
				   <p>
                  With no set-up fee, this is a low-cost solution with high reward
                  </p>
                  <p>
                  PayTack is a cost- and time-effective solution that increases customer loyalty and boosts revenues
                  </p>
                </div>
              </Fade>
              <Fade right>
                <div className="container-body__card-right text-left">
                  <img className="icons" src={Icon2} alt="icon1" />
                </div>
              </Fade>
            </div>
            <div className="container-body__card" style={{paddingTop:'40px'}}>
              <Fade left>
                <div className="container-body__card-left text-right  px-100">
                  <img className="icons" src={Icon3} alt="icon1" />
                </div>
              </Fade>
              <Fade right>
                <div className="container-body__card-right text-left">
                  <h2 className="container-body__card-title">
                  Increase Customer Lifetime value
                  </h2>
				  <p>
                  Capture increased transaction share by ensuring your brand is always top-of-mind, and competitively incentivised, so consumers choose your brand first
                  </p>
				  <p>
                  By engaging and rewarding customers, ensure your business is always first choice
                  </p>
                  <p>
                  Using PayTack encourages customers to return to your business again and again, allowing you to increase your customer base
                  </p>
                </div>
              </Fade>
            </div>
            <div className="container-body__card grid-order__change" style={{paddingTop:'40px'}}>
              <Fade left>
                <div className="container-body__card-left px-100 text-left">
                  <h2 className="container-body__card-title">
                  Wider Customer Pool
                  </h2>
				  <p>
                 Offers are displayed in app and on our website, with push-marketing increasing the reach of your brand
                  </p>
                  <p>
                 Gain access to a customer pool of millions by listing offers in our app and on the PayTack website, boosting your brand’s reach and visibility
                  </p>
                </div>
              </Fade>
              <Fade right>
                <div className="container-body__card-right text-left">
                  <img className="icons" src={Icon4} alt="icon1" />
                </div>
              </Fade>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default HomeRetain;
