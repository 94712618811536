import React from "react"
import LogoElement from "../images/home/benefits-images/rectangle-30.svg"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import logo from "../images/newlogo.png"
import mobileLogo from "../images/mobileLogo.png"
import { Link } from "react-router-dom"

const Logo = styled(Link)`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${logo});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -50px;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
    .logo_img {
      background-image: url(${mobileLogo}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
      margin-top: -70px;
    }
  }
`

const GoToTop = styled.div`
  position: absolute;
  bottom: -70px;
  right: 25px;
  padding: 0 30px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Terms = () => {
  return (
    <div className="head-bg">
      <section className="container privacy pt-100">
        <Logo to="/">
          <div className="logo_img"></div>
        </Logo>
        <Zoom>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={LogoElement}
              alt="bubble"
            />
            <h1 className="container-heading__sub-title primary-color">
              GENERAL TERMS & CONDITIONS
            </h1>
          </div>
        </Zoom>
        <div className="container-body" style={{ position: "relative" }}>
          <GoToTop onClick={() => window.scrollTo(0, 0)}>Go To Top</GoToTop>
          <Fade bottom>
            <div className="pt-100 px-100">
              <p className="font-italic">Last update 09 May 2022.</p>
              <h1 className="container-heading__sub-header primary-color">
                1. INTRODUCTION
              </h1>
              <p>
                PayTack App is an online loyalty program where you as a user can
                obtain various benefits from the participating companies
                (hereinafter referred to as the "Cashback" service). The service
                is provided by Paytack ApS, CVR no. 40209751, C/O Matrikel
                1,Højbro Plads 10, 1200 København K, Denmark. (“PayTack”, “us”,
                “our” or “we”).
              </p>
              <p>
                These General Terms and Conditions (“Terms”) govern your access
                to, and use of, the Services provided by PayTack APS. When you
                access or use the Services, you agree to all of these Terms.
              </p>
              <p>
                Your use of our Services is also subject to our Privacy Policy.
                Privacy is very important to us at PayTack. We ask that you read
                these Terms and the Privacy Policy carefully.
              </p>
              <p>
                If you have got questions after reading these Terms and the
                Privacy Policy in its entirety, please feel free to contact us
                at support@paytack.com
              </p>
              <h1 className="container-heading__sub-header primary-color">
                2. DEFINITIONS
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                  <span className="font-weight-bold">Account:</span> means an
                  account registered by a User.
                </li>
                <li>
                  <span className="font-weight-bold">Affiliates</span> means
                  subsidiaries, franchisees and other legal entities with which
                  PayTack has binding contracts regarding the provision of the
                  Services, including companies contributing in the creation
                  and/or provision of the Services.
                </li>
                <li>
                  <span className="font-weight-bold">Application or App: </span>
                  means the smartphone application provided by PayTack
                  applicable on iOS Appstore, Google Play or any other market
                  authorized by PayTack.
                </li>
                <li>
                  <span className="font-weight-bold">Merchant</span>means any
                  third-party service provider whose services User can subscribe
                  to and enter into a separate agreement with concerning the
                  purchase of products or services.
                </li>
                <li>
                  <span className="font-weight-bold">Consent: </span>means the
                  consent given by a user for the processing of his or her data
                  in compliance with Data Protection Laws.
                </li>
                <li>
                  <span className="font-weight-bold">
                    Data Protection Laws:{" "}
                  </span>
                  means the General Data Protection Regulation (EU) 2016/679 of
                  the European Parliament and the Council of 27 April 2016 on
                  the protection of natural persons regarding the processing of
                  personal data and on the free movement of such data and
                  repealing the Directive (the “GDPR”), National Data Protection
                  Laws, and any Applicable laws and/or regulations implementing
                  the GDPR.
                </li>
                <li>
                  <span className="font-weight-bold">Bank Link: </span> means
                  the Linking of bank account to your purchase data with red
                  only access for the purpose of identifying qualified purchase
                  for cashback eligibility
                </li>
                <li>
                  <span className="font-weight-bold">Cashback: </span>means
                  bonus given by the participating merchants on a qualified
                  purchased
                </li>
                <li>
                  <span className="font-weight-bold">Personal Data: </span>means
                  any information relating to an identified or identifiable
                  natural person and protected as personal data under applicable
                  Data Protection Laws.
                </li>
                <li>
                  <span className="font-weight-bold">Services:</span> defined in
                  Section 3.
                </li>
                <li>
                  <span className="font-weight-bold">Redeem: </span> means to
                  use Cashback balance for the next purchase
                </li>
                <li>
                  <span className="font-weight-bold">User: </span>means a
                  natural person in his or her capacity as a consumer.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                3. PROVISION OF SERVICES
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                <span className="font-weight-bold">3.1</span> PayTack is a cashback service intermediary between the
                  User, in his or her capacity as a consumer, and a Merchant.
                  Thus, PayTack does not act as a payment service provider and
                  your Account is not a payment account.
                </li>
                <li>
                  <span className="font-weight-bold">3.2</span> These terms of use apply to all Cashback's services,
                  including both the web service paytack.com (hereinafter
                  referred to as the “Website”) and the Paytack App (hereinafter
                  referred to as the “App”).
                </li>
                <li>
                  <span className="font-weight-bold">3.3 Creating a user profile</span>
                  <ul className="sub-list">
                    <li>
                      To use Cashback's services, you must create a user accout.
                      Creating a user account requires that you enter at least
                      your name,email address, your country ,city or zip code
                      and a password. Your email adress is the unique identity
                      customer number. You must provide this customer number
                      when you use Paytack's support.
                    </li>
                    <li>
                      In order to receive a bonus via Paytack, you may also link
                      your bank or banks,using your bank’s authentication
                      method. We get read only access to your transaction data
                      in order to qualify your purchase for the cashback amount.
                      Bank linking is done via our partner Tink AB that are
                      authorized to handle banking data and provides highly
                      secure forms that is PCI-DSS compliant .Your bank account
                      will never be charged by paytack or our partner Tink AB.
                      This is voluntary
                    </li>
                    <li>
                      To take full advantage of all the features of Cashback,
                      you can provide a number of additional information about
                      yourself, such as, date of birth,Date of anniversary age
                      and gender, as well as other types of information that you
                      provide to Paytack. This is voluntary.
                    </li>
                    <li>
                      You are responsible for ensuring that the information
                      provided to Paytack is always correct. If you want to make
                      changes to the information you have registered in your
                      user profile, eg if you get a new bank account number or
                      want to change your contact information, such changes can
                      be made on the App.
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="font-weight-bold">3.4 Cashbacks</span>
                  <br />
                  Every time you shop in the many physical stores, chains that
                  are affiliated with Paytack, you get a cashback when you use
                  the paytack affiliated bank eg. bank card or digital wallet as
                  a means of payment.The cashback can only be redeemed for the
                  next purchase at the affiliate partners.there is no cash
                  transfer or cash earning in this service.
                  <br />
                  What cashback you get and for what varies from company to
                  company, but always appears in the company's point of sale, in
                  the App and on the Website. The cashback you receive is
                  subject to change without notice. It is always the cashback
                  rate that was applicable at the time of your payment with the
                  cashback associated with the Cashback used.
                  <br />
                  Cashback can be calculated differently from company to
                  company. It can be calculated by:
                  <ul className="alphabet-list">
                    <li>
                      your entire payment
                      <br />
                      (eg "10% of the entire payment", where you will earn DKK
                      20.00 in cashback on a purchase of DKK 200.00),
                    </li>
                    <li>
                      be conditional on a purchase for a certain amount
                      <br />
                      (eg "10% of the payment on purchase for a minimum of DKK
                      200.00" where you will collect DKK 20.00 in cashback on a
                      purchase of DKK 200.00, and no cashback , also not
                      proportionately calculated if the condition is not met, ie
                      for purchases of DKK 199.99 or less in the example), or
                    </li>
                    <li>
                      be a maximum cashback
                      <br />
                      (eg "10% of the payment, however, max DKK 50.00", where
                      you will collect DKK 50.00 in cashback on purchases of DKK
                      1,000 and over)
                    </li>
                    <li>
                      on another basis, which appears on the affiliate, in the
                      App and on the Website.
                      <ul className="sub-list">
                        <li>
                          You should always pay the full price in the affiliate
                          company. Cashback is always calculated from your
                          payment to the affiliated company incl. VAT, and can
                          only be redeemed as mentioned in 6. There may be
                          companies, which are so-called "Affiliate Partners" -
                          where the cashback is deducted from VAT, because some
                          "Affiliate Partners" do not pay cashback with VAT.
                        </li>
                        <li>
                          Once you have completed a cashback-eligible payment,
                          the cashback will be recorded in your paytack account.
                          However, it may take a short time before the
                          registration can be seen in the App .
                        </li>
                        <li>
                          Cashback registration on your paytack account may take
                          upto 4 working days, it is because your purchase data
                          needs to be verified with your bank’s transaction and
                          we may not get the transaction data immediately from
                          your bank
                        </li>
                        <li>
                          If you make a cashback-eligible payment, which is
                          subsequently canceled, eg because you regret your
                          purchase from the company affiliated with Cashback,
                          Cashback is entitled to cancel the cashback earned
                          upon payment. If the balance on your Paytack account
                          becomes negative, Paytack is entitled to adjust an
                          amount from your next cashback balance, so that your
                          balance goes to zero.
                        </li>
                        <li>
                          You can validate your purchase manually by displaying
                          an uploaded image of your receipt via the App for the
                          stores that do not accept payment cards: This program
                          is called snap a bill. However, cashback can only be
                          earned here if you have a purchase receipt from an
                          approved partner, where part of the 16-digit card
                          number and the date of the receipt are visible, and
                          this is uploaded to the App within 7 days of your
                          purchase.
                        </li>
                        <div>Cashback Redemption</div>
                        <li>
                          You can Redeem part of your cashback balance or total
                          cashback balance for your next purchase at a
                          particular merchant or any other merchant as per the
                          cashback providing merchant cashback offer terms (eg.
                          Merchant A offers cashback to be redeemed anywhere
                          therefore you can redeem entire balance either at
                          merchant A or any other merchant that are our
                          affiliate partners, however, if Merchant B offers
                          cashback redemption only at their shop then you cannot
                          redeem at any other merchant)
                        </li>
                        <div>Communication from Paytack</div>
                        <li>
                          Paytack can continuously inform you about important
                          information e.g. about changes to these conditions via
                          the e-mail address you enter in connection with the
                          creation of your user profile, and / or via push
                          notifications in the App.
                        </li>
                        <li>Consent regarding inquiry from Paytack</li>
                        <li>
                          With your consent, Paytack can send newsletters,
                          notifications about new functions and other material
                          regarding Paytack to your e-mail address and / or via
                          push notifications in the App. Such inquiries may
                          include information about your balance, special offers
                          and options for using Paytack. You can in the App can
                          select and deselect the types of inquiries you want to
                          receive from Paytack
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="font-weight-bold">3.5 </span>When purchasing
                  any product or service from a Merchant, PayTack is not liable
                  for performance of said Merchant’s obligation. You are aware,
                  acknowledge and agree that any purchase is made in accordance
                  with said Merchant’s terms and conditions.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                4. Legal relationship
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                  <span className="font-weight-bold">4.1 </span>You must be a
                  natural person of at least 16 years of age to agree to these
                  Terms and use the Services. Use of certain Services may have
                  further eligibility requirements that will need to be verified
                  prior to you using such Services. Any use of the Services by
                  an unregistered person is not authorized.
                </li>
                <li>
                  <span className="font-weight-bold">4.2 </span>You specifically
                  agree to use the Services only for your own personal use as a
                  consumer and no other purpose such as, but not limited to, the
                  use of any other person’s personal data.
                </li>
                <li>
                  <span className="font-weight-bold">4.3 </span>The access, or
                  attempted access, of the Services by another person than the
                  User is not permitted under these Terms. User is not permitted
                  to authorize another person access to User’s Account.
                </li>
                <li>
                  <span className="font-weight-bold">4.4 </span>You may register
                  one (1) Account. If you attempt to open more than one (1)
                  account, such additional account(s) may be suspended or
                  terminated.
                </li>
                <li>
                  <span className="font-weight-bold">4.5 </span>We may also not
                  provide all or some of our Services to certain persons or
                  regions if, for example, in doing so, we would not be in
                  compliance with the laws relating to our Services in such
                  jurisdictions.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                5. YOUR USE OF THE SERVICES
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                  <span className="font-weight-bold">5.1</span> You agree to use
                  the Services only for purposes that are permitted by (a) these
                  Terms, (b) any applicable guidelines or policies provided by
                  PayTack (including through the Services) and (c) law,
                  regulation or generally accepted practices or guidelines in
                  the relevant jurisdictions (including any Data Protection Laws
                  or associated laws regarding the export of data or software to
                  and from Denmark, the European Union or other relevant
                  countries).
                </li>
                <li>
                  <span className="font-weight-bold">5.2</span> You agree that
                  PayTack may close, suspend, or limit your access to your
                  Account and the Services (or longer if pursuant to a court
                  order or other legal process) if you violate these Terms or
                  any other agreement you have entered into with PayTack, or as
                  may be otherwise specified in these Terms.
                </li>
                <li>
                  <span className="font-weight-bold">5.3</span> You agree that
                  PayTack reserve the right to change, suspend, or discontinue
                  any aspect of the Services at any time, including hours of
                  operation or availibility of any feature, without notice and
                  without Liability. We may decline to process any purchase
                  without prior notice. Suspension of your use of any of the
                  Services will not affect your rights and obligations pursuant
                  to these Terms.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                6. YOUR PRIVACY AND PERSONAL DATA
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                  <span className="font-weight-bold">6.1</span> Your access to,
                  and use of, the Services is subject to our Privacy Policy, as
                  modified by PayTack from time to time. The Privacy Policy
                  explains how we collect, use, share and store the personal
                  data we receive and collect from you, forms an essential part
                  of these Terms and by accepting these Terms you agree to the
                  use of your personal data in accordance with the Privacy
                  Policy.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                7. SECURITY
              </h1>
              <ul className="Poppins-light-22px-black">
                <li>
                  <span className="font-weight-bold">7.1</span> You specifically
                  agree not to (a) access (or attempt to access) the Services by
                  any means other than through the interface of the Application,
                  unless you have been specifically allowed to do so in a
                  separate written agreement with PayTack; (b) access (or
                  attempt to access) the Services through any automated means
                  (including, but not limited to, use of scripts, robots, agents
                  or web crawlers); (c) interfere with or disrupt the Services,
                  or servers or networks connected to the Services; or (d) take
                  any other actions that can be damaging in relation to PayTack
                  or our Affiliates, the Services or third parties.
                </li>
                <li>
                  <span className="font-weight-bold">7.2</span> You are
                  responsible for maintaining the confidentiality and security
                  of any and all Account names, user IDs, passwords, personal
                  identification numbers, and mobile unlock codes that you can
                  use to access the Services. You agree to notify PayTack
                  immediately if you become aware of any unauthorized use of the
                  Services or any other breach of security regarding the
                  Services at support@paytack.com. PayTack will not be liable
                  for any loss or damage arising from your failure to protect
                  your Account.
                </li>
                <li>
                  <span className="font-weight-bold">7.3</span> You agree that
                  if you become aware of any unauthorized charge occurring
                  through your Account, you should contact PayTack customer
                  support as soon as possible via phone or via e-mail.
                </li>
                <li>
                  <span className="font-weight-bold">7.4</span> You agree that
                  we may, at our sole discretion, delay purchases processing or
                  withdrawals if we believe the transaction is suspicious, may
                  involve fraud, violates applicable laws or violates these
                  Terms.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                8. PROPERTY RIGHTS
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">8.1</span> You are aware,
                  acknowledge and agree that PayTack, its Affiliates and/or
                  service providers owns all legal right, title and interest in
                  and to the Services, including any intellectual property
                  rights which subsist therein (whether those rights happen to
                  be registered or not, and wherever in the world those rights
                  may exist) and that you do not acquire any right, title or
                  interest in or to the Services unless expressly set forth in
                  these Terms.
                </li>
                <li>
                  <span className="font-weight-bold">8.2</span> Nothing in these
                  Terms gives you a right to use any of PayTack’s trade names,
                  trademarks, service marks, logos, domain names or other
                  distinctive brand features, unless otherwise agreed in writing
                  with PayTack.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                9. EXTERNAL LINKS
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">9.1</span> The Services may
                  include hyperlinks to web sites, content or resources provided
                  by Merchant. PayTack may have no control over any web sites,
                  content or resources which are not provided by PayTack.
                </li>
                <li>
                  <span className="font-weight-bold">9.2</span> You are aware,
                  acknowledge and agree that PayTack is not responsible for the
                  availibility of any external sites (including any provided by
                  Merchant) content or resources, and does not endorse any
                  advertising, products or other materials on or available from
                  such web sites, content or resources and that PayTack is not
                  liable for any loss or damage which may be incurred by you as
                  a result of the availability of those external sites, content
                  or resources, or as a result of any reliance placed by you on
                  the completeness, accuracy or existence of any advertising,
                  products, information or other materials on, or available
                  from, such web sites, content or resources.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                10. GENERAL PROVISIONS
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">10.1</span> You are aware,
                  acknowledge and agree that these Terms constitutes the entire
                  agreement and understanding between you and PayTack relating
                  to the subject matter hereof (but excluding any services which
                  PayTack may provide to you under a separate written agreement)
                  and supersedes all written or oral commitments, undertakings
                  and agreements which have preceded these Terms.
                </li>
                <li>
                  <span className="font-weight-bold">10.2</span> You are aware,
                  acknowledge and agree that any amendment, change or
                  modification of these Terms, other than as set out Above, may
                  only be made by a written agreement between the parties.
                </li>
                <li>
                  <span className="font-weight-bold">10.3</span> You are aware,
                  acknowledge and agree that in no event shall any delay,
                  failure or omission of a Party in enforcing, exercising or
                  pursuing any right, claim or remedy under these Terms be
                  deemed as a waiver thereof, unless such right, claim or remedy
                  has been expressly waived in writing.
                </li>
                <li>
                  <span className="font-weight-bold">10.4</span> If any court of
                  law, having the jurisdiction to decide on this matter, finds
                  that any provision of these Terms (or the application thereof)
                  shall be declared or deemed void, invalid or unenforceable in
                  whole or in part for any reason, that provision shall be
                  enforced to the maximum extent permissible so as to affect the
                  intent of these Terms and the remaining provisions of these
                  Terms shall continue in full force and effect.
                </li>
                <li>
                  <span className="font-weight-bold">10.5</span> The English
                  language shall be the governing language in your relationship
                  with PayTack, unless otherwise required by mandatory national
                  law.
                </li>
                <li>
                  <span className="font-weight-bold">10.6</span> The User shall
                  not be entitled to assign its rights and/or obligations under
                  these Terms without PayTack’s prior written approval. PayTack
                  shall be entitled to assign its rights and/or obligations
                  under these Terms without the prior written approval of the
                  User.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                11. INDEMNITY
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">11.1</span> You agree to
                  hold harmless and indemnify PayTack, its Affiliates and each
                  of their respective directors, officers, agents, and employees
                  from and against any third party claim arising from or in any
                  way related to your use of the Services, violation of these
                  Terms or any other actions connected with your use of the
                  Services, including any liability or expense arising from all
                  claims, losses, damages (actual and consequential), suits,
                  judgments, litigation costs and attorneys' fees, of every kind
                  and nature. In such a case, PayTack will provide you with
                  written notice of such claim, suit or action. PayTack shall
                  have the right to participate, at its own expense, in the
                  defense of any claim. You shall not agree to any settlement of
                  any claim as set out above without the prior written consent
                  of PayTack.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                12. LIMITATION OF LIABILITY
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">12.1</span> You are aware,
                  acknowledge and agree that PayTack, Affiliates, service
                  providers and their respective officers, directors, agents,
                  joint venturers, employees, and representatives will not be
                  liable for any indirect, incidental, special, consequential,
                  exemplary damages, or damages for loss of profits including
                  but not limited to, damages for loss of goodwill, use, data,
                  or other intangible losses (even if PayTack has been advised
                  of the possibility of such damages), whether based on
                  contract, tort, negligence, strict liability, or otherwise,
                  resulting from (a) the use or the inability to use the
                  services; (b) the cost of procurement of substitute goods and
                  services resulting from any goods, data, information, or
                  services purchased or obtained or messages received or
                  transactions entered into through or from the services; (c)
                  unauthorized access to or alteration of your transmissions or
                  data; or (d) any other matter relating to the Services.
                </li>
                <li>
                  <span className="font-weight-bold">12.2</span> Some
                  jurisdictions do not allow the exclusion of certain warranties
                  or the limitation or exclusion of liability for incidental or
                  consequential damages. Accordingly, some of the limitations
                  set forth above may not apply to you. If you are dissatisfied
                  with any portion of the services or with these Terms, your
                  sole and exclusive remedy is to discontinue use of the
                  Services and delete your Account.
                </li>
                <li>
                  <span className="font-weight-bold">12.3</span> The Services
                  are provided “as is”, “as available” and without any
                  representation or warranty, whether express, implied, or
                  statutory. PayTack, our Affiliates, and our respective
                  officers, directors, agents, joint venturers, employees, and
                  suppliers specifically disclaim any implied warranties of
                  title, merchantability, fitness for a particular purpose or
                  non-infringement. PayTack makes no warranty that (a) the
                  Services will meet your requirements, (b) the Services will be
                  uninterrupted, timely, secure, or error-free, or (c) the
                  quality of any products, services, information, or other
                  material purchased or obtained by you will meet your
                  expectations.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                13. GOVERNING LAW AND JURISDICTION
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">13.1</span> These Terms, and your relationship with PayTack under
                  these Terms, shall, to the maximum extent permitted by
                  applicable mandatory law in your jurisdiction, be governed by
                  and construed in accordance with the substantive laws of
                  Denmark without giving effect to the choice of law principles
                  thereof.
                </li>
                <li>
                  <span className="font-weight-bold">13.2</span> Any dispute,
                  controversy or claim arising out of or in connection with
                  these Terms, or the breach, termination or invalidity thereof,
                  shall be settled by a Danish court of general jurisdiction and
                  the Copenhagen City Court (Danish: Københavns Byret) shall be
                  the court of first instance.
                </li>
                <li>
                  <span className="font-weight-bold">13.3</span> Notwithstanding
                  the above, you are aware, acknowledge and agree that Paytack
                  shall always be allowed to apply for injunctive remedies (or
                  an equivalent type of urgent legal relief) in any jurisdiction
                  and through any court of competent jurisdiction.
                </li>
              </ul>
              <h1 className="container-heading__sub-header primary-color">
                14. TERMS
              </h1>
              <ul>
                <li>
                  <span className="font-weight-bold">14.1</span> PayTack may
                  make changes to these Terms from time to time. Any changes to
                  these Terms will be notified to you notice through our
                  Services, or by other means, to provide you the opportunity to
                  review the changes before they become effective. You are
                  aware, acknowledge and agree that your continued use of the
                  Services after the date on which these Terms have changed as
                  set out above will constitute your acceptance of the changed
                  Terms. If you object to any changes, you may delete your
                  Account.
                </li>
                <li>
                  <span className="font-weight-bold">14.2</span> These Terms
                  enters into force on the date you first agree to them (as set
                  out under Section 4) and will continue in force thereafter
                  until terminated by either you or PayTack as set out below.
                  Upon termination of these Terms for any reason, you must
                  immediately cease using the Services.
                </li>
                <li>
                  <span className="font-weight-bold">14.3</span> These Terms are
                  terminated if you delete your Account.
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  )
}

export default Terms
