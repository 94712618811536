import React from "react";
import LogoElement from "../../images/home/benefits-images/rectangle-30.svg";

import about1 from "../../images/aboutus/about-icon1.svg";
import about2 from "../../images/aboutus/about-icon2.svg";
import about3 from "../../images/aboutus/about-icon3.svg";
import about4 from "../../images/aboutus/about-icon4.svg";

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

const About = () => {
  return (
    <>
      <section className="container about-us pt-100">
        <Bounce>
        <div className="container-heading">
          <img
            className="container-heading__image"
            src={LogoElement}
            alt="bubble"
          />
          <h1 className="container-heading__title">About Us</h1>
        </div>
        </Bounce>
        <div className="container-body about-section">
          <Fade left>
          <div className="container-body__card justify-flex__center">
            <div className="container-body__left w-25 px-75">
              <img className="container-body__icon" src={about1} alt="" />
            </div>
            <div className="container-body__left w-50">
              <p>
              PayTack was founded by Santosh Kumar in 2018 in Malmö, Sweden and later registered in Copenhagen as PayTack IVS in January 2019. We were part of Minc incubation and Copenhagen fast track Nordics incubation programs and have received valuable feedback that has helped us to validate product-market fit.              </p>
            </div>
          </div>
          </Fade>
          <Fade right>
          <div className="container-body__card justify-flex__end">
            <div className="container-body__left w-25 px-50">
              <img className="container-body__icon" src={about2} alt="" />
            </div>
            <div className="container-body__left w-50">
              <p>
              Our goal with PayTack has always been to create a simple and affordable platform for small to medium businesses. We want to give them access to their customers' data, on a level that is normally only possible for much larger, online shops such as Amazon and eBay. We empower them to understand their customers' behaviour, preferences, and profiles, to target them effectively and at an affordable price.
              </p>
            </div>
          </div>
          </Fade>
          <Fade left>
          <div className="container-body__card justify-flex__center">
            <div className="container-body__left w-25 px-50">
              <img className="container-body__icon" src={about3} alt="" />
            </div>
            <div className="container-body__left w-50">
              <p>
              PayTack is on a mission to provide local brands with the tools and technology they need to win in a customer-centric world. We want consumers to use Paytack to discover local offers, save money on their everyday purchases, and share them with their friends and community. We believe innovation should be simple and affordable for everyone.
              </p>
            </div>
          </div>
          </Fade>
          <Fade right>
          <div className="container-body__card justify-flex__end">
            <div className="container-body__left w-25 px-50">
              <img className="container-body__icon" src={about4} alt="" />
            </div>
            <div className="container-body__left w-50">
              <p>
              Our team is our biggest strength and we work together to create the next big thing. We have cross functional talents with a wide range of expertise and skills - from passionate developers to digital strategists and everything in between!
              </p>
            </div>
          </div>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default About;
