import React, { useState } from "react";
import HeroBanner from "../components/Home/HeroBanner";
import HomeRetain from "../components/Home/HomeRetain";
import HowItWorks from "../components/Home/HowItWorks";
import BenefitEveryone from "../components/Home/BenefitEveryone";
import CustomerSlicks from "../components/Home/CustomerSlicks";
import FAQ from "../components/Home/FAQ";
import Contact from "../components/Home/Contact";
// import Subcribtion from '../components/Home/Subcribtion'
import Sidebar from "../components/Navbar/Sidebar";
import ScrollTopPage from "../components/ScrollTopPage";

import { menuDataHome } from "../data/MenuData";
import Navbar from "../components/Navbar/Navbar";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <ScrollTopPage />
      <Navbar linksData={menuDataHome} menuToggle={menuToggle} />
      <Sidebar
        linksData={menuDataHome}
        isMenuOpen={isMenuOpen}
        menuToggle={menuToggle}
      />
      <HeroBanner />
      <HomeRetain />
      <HowItWorks />
      <BenefitEveryone />
      {/* Commenting below section of Our Customers */}
      {/* <CustomerSlicks /> */}
      {/* <FAQ /> */}
      <Contact />
    </>
  );
};

export default Home;
