import React from "react";

import Group7455 from "../images/customers/Group-7455.png";
import Group7354 from "../images/customers/Group7354.png";
import Group7511 from "../images/customers/Group-7511.png";
import Group7553 from "../images/customers/Group7553.png";
import Group7554 from "../images/customers/Group7554.png";
import Group7555 from "../images/customers/Group7555.png";
import Group7379 from "../images/customers/Group7379.png";
import Group7382 from "../images/customers/Group7382.png";
import Group7556 from "../images/customers/Group7556.png";
import Group7384 from "../images/customers/Group7384.png";
import Group7557 from "../images/customers/Group7557.png";
import Group7558 from "../images/customers/Group7558-new.png";
import Group7559 from "../images/customers/Group7559.png";

import FAQ from "../components/Home/FAQ";

import Pulse from "react-reveal/Pulse";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

import "../styles/Localty.css";

const Loyalty = () => {
  return (
    <div className="localty-app loyalty">
      <section className="hero-banner">
        <Fade>
          <div className="container hero-banner__container grid-order__change">
            <div className="hero-banner_container-left">
              <h1 className="hero-main__title">
                Earning cashback is made simple and automatic!
              </h1>
              <div className="hero-paragraph py-25">
                Visit our partners locations and receive cashback benefits
                directly into your bank account
              </div>
              <div className="btn-group__flex my-25">
                <button className="btn-primary">Explore</button>
                <div className="btn-image">
                  <img
                    src={Group7354}
                    alt=""
                    className="mobile-customers-img"
                  />
                </div>
              </div>
            </div>
            <div className="hero-banner_container-right">
              <img src={Group7455} alt="" className="phone-img" />
            </div>
          </div>
        </Fade>
      </section>

      <div className="loyalty-bg-container">
        <section className="container loyalty-banner">
          <div className="hero-banner__container pt-25">
            <Fade left>
              <div className="hero-banner_container-left px-50">
                <img src={Group7511} alt="" className="img" />
              </div>
            </Fade>
            <Fade right>
              <div className="hero-banner_container-right">
                <h1 className="hero-main__title">
                  Organize your purchases, every month!
                </h1>
                <div className="hero-paragraph">
                  Better visibility of your spendings to help you analyse and
                  save them for other purpose.
                  <br />
                  Organize your receipts or purchases this month
                </div>
              </div>
            </Fade>
          </div>
        </section>
      </div>

      <section className="container loyalty-banner">
        <div className="hero-banner__container pt-25 grid-order__change">
          <Fade left>
            <div className="hero-banner_container-left">
              <h1 className="hero-main__title">
                Redeem your cashback anwhere!
              </h1>
              <div className="hero-paragraph">
                Our unique loyality membership allows you to redeem your earned
                cash back at any of the participating merchants within our
                network
              </div>
            </div>
          </Fade>
          <Fade left>
            <div className="hero-banner_container-right px-50">
              <img src={Group7553} alt="" className="img" />
            </div>
          </Fade>
        </div>
      </section>

      <section className="container loyalty-banner">
        <div className="hero-banner__container pt-25">
          <Fade left>
            <div className="hero-banner_container-left px-50">
              <img src={Group7554} alt="" className="img" />
            </div>
          </Fade>
          <Fade left>
            <div className="hero-banner_container-right">
              <h1 className="hero-main__title">
                Get your cashback into your bank or give back
              </h1>
              <div className="hero-paragraph">
                PayTack rewards app allows your to shop at your favorite brands
                and the earned cashbacks can be directly tranferred to your bank
                account or give it back for a noble cause. It's up to you
              </div>

              <form action="" className="container-body__from-send-app py-25">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="201-555-0123"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                  ></input>
                  <div className="input-group-append">
                    <span
                      className=" input-group-text share-button"
                      id="basic-addon2"
                    >
                      Send me app link
                    </span>
                  </div>
                </div>
              </form>
              {/* <button className="btn-primary">Send me app link</button> */}
            </div>
          </Fade>
        </div>
      </section>

      <div className="loyalty-how-it-works">
        <section className="container pt-50">
          <Bounce>
            <div className="container-heading">
              <h1 className="container-heading__title">How it works?</h1>
            </div>
          </Bounce>
          <div className="container-body">
            <div className="container-body__card py-25 ">
              <Pulse left>
                <div className="container-body__card-left">
                  <img className="number-icons" src={Group7379} alt="icon1" />

                  <div className="container-body__card-content">
                    <h2 className="container-body__card-title">
                      Download payTack app
                    </h2>
                    <p>
                      Download PayTack app and allow location service and
                      notification to be enabled so we can send you closest
                      offers and cashback available near you{" "}
                    </p>

                    <div className="app-details__btns py-25">
                      <a
                        className="share__link"
                        href="https://apps.apple.com/us/app/paytack/id1502442601?ls=1"
                      >
                        <div className="app-details__btn apple-share"></div>
                      </a>{" "}
                      <br />
                      <a
                        className="share__link"
                        href="https://play.google.com/store/apps/details?id=com.paytack.application&hl=en_US"
                      >
                        <div className="app-details__btn playstore-share"></div>
                      </a>
                    </div>
                    {/* <div className="btn-group__flex">
                  <button type="button" className="btn-primary">
                    App Store
                  </button>
                  <button type="button" className="btn-primary">
                    Google Play
                  </button>
                </div> */}
                  </div>
                </div>
              </Pulse>
              <Pulse right>
                <div className="container-body__card-right">
                  <img src={Group7555} alt="icon1" />
                </div>
              </Pulse>
            </div>
            <div className="container-body__card py-25">
              <Pulse left>
                <div className="container-body__card-left">
                  <img className="number-icons" src={Group7382} alt="icon1" />

                  <div className="container-body__card-content">
                    <h2 className="container-body__card-title">
                      Discover places near you
                    </h2>
                    <p>
                      You can find all participating partners using map or list
                      view and can check the rewards that are waiting for you
                    </p>
                  </div>
                </div>
              </Pulse>
              <Pulse right>
                <div className="container-body__card-right">
                  <img src={Group7556} alt="icon1" />
                </div>
              </Pulse>
            </div>
            <div className="container-body__card py-25">
              <Pulse left>
                <div className="container-body__card-left">
                  <img className="number-icons" src={Group7384} alt="icon1" />

                  <div className="container-body__card-content">
                    <h2 className="container-body__card-title">
                      Shop online or offline
                    </h2>
                    <p>
                      Simply use our app to pay to earn automatic ashbacks or
                      pay at the shop and scan the bill using our app to earn
                      cashbacks. You can redeem or transfer it directly into
                      your
                    </p>
                  </div>
                </div>
              </Pulse>
              <Pulse right>
                <div className="container-body__card-right">
                  <img src={Group7557} alt="icon1" />
                </div>
              </Pulse>
            </div>
          </div>
        </section>
      </div>

      <section className="container join-us pb-0">
        <div className="container-heading">
          <Bounce>
            <h1 className="container-heading__title">
              Join us in helping hyperlocal community
            </h1>
          </Bounce>
        </div>
        <Fade bottom>
          <div className="container-body">
            <img
              width="94%"
              src={Group7558}
              alt=""
              className="mobile-customers-img"
            />

            <div className="join-us__bottom">
              <p className="pb-50">
                The money you earn through PayTack's reward can help to make our
                underprivileged children educated. Join us in supporting an
                important UnitedNations projects in India
              </p>
              <button type="button" className="btn btn-primary">
                Read more
              </button>
            </div>
          </div>
        </Fade>
      </section>

      <section className="container join-us">
        <Pulse>
          <div className="container-heading">
            <h1 className="container-heading__title">
              Secure and private - PayTack value your security and privacy above
              all else.
            </h1>
          </div>
        </Pulse>
        <div className="container-body loyalty-security">
          <div className="container-body__card pb-0">
            <Fade left>
              <div className="container-body__card-left">
                <img src={Group7559} alt="icon1" />
              </div>
            </Fade>
            <Fade right>
              <div className="container-body__card-right">
                <Fade>
                  <div className="loyalty-security_titlle">
                    Payment data encryption
                  </div>

                  <p className="mt-3">
                    We don't store any payment card data and all the payment
                    details are 256 bit encrypted for end to end communication
                    with the help of our global fintech partners
                  </p>
                </Fade>
                <Fade>
                  <div className="loyalty-security_titlle">
                    Secure personal data
                  </div>
                  <p className="mt-3">
                    Your data is only shared with our partners that your choose
                    to get cashback from, others will never have any access to
                    your private data
                  </p>
                </Fade>
                <Fade>
                  <div className="loyalty-security_titlle">
                    Cloud security to data access
                  </div>
                  <p className="mt-3">
                    Our secure cloud based architecture makes it impossible for
                    unauthorized access to our system and data are masked to
                    protect individual information being stolen or misused
                  </p>
                </Fade>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* <FAQ /> */}
    </div>
  );
};

export default Loyalty;
