import React, { useState } from "react";

import OurTeam from "../components/AboutUs/OurTeam";
import About from "../components/AboutUs/About";

import "../styles/AboutUs.css";
import Sidebar from "../components/Navbar/Sidebar";
import ScrollTopPage from "../components/ScrollTopPage";

import { menuDataOther } from "../data/MenuData";
import Navbar from "../components/Navbar/Navbar";

const AboutUs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <ScrollTopPage />
      <Navbar linksData={menuDataOther} menuToggle={menuToggle} />
      <Sidebar
        linksData={menuDataOther}
        isMenuOpen={isMenuOpen}
        menuToggle={menuToggle}
      />
      <div className="about-section head-bg">
        <About />
        <OurTeam />
      </div>
    </>
  );
};

export default AboutUs;
