import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import styled from "styled-components";
import "../../styles/Home.css";

// import herobannerImage from "../../images/home/phone2.svg";
import herobannerImage from "../../images/home/art.png";
import herobannerSubImage from "../../images/home/art-sub.png";
import MobileHomeTwo from '../../images/mobile-home.png'
import MobileHomeOne from '../../images/mobile-home-one.png'
import Group7354 from "../../images/customers/Group7354.png";
import Group7455 from "../../images/customers/Group-7455.png";
import Slide from "react-reveal/Slide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroBannerSlick from "react-slick";

import Fade from "react-reveal/Fade";
import PartnerModal from "./PartnerModal";

const HeroBanner = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !modalIsOpen,
    autoplaySpeed: 5000,
  };

  const partnerModal = () => {
    document.body.style.overflow = "hidden";
    setModalIsOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "auto";
    setModalIsOpen(false);
  };

  return (
    <Slide top>
      <section className="hero-banner" id="home">
        <HeroBannerSlick {...settings}>
          <div className="hero-banner__slick">
            <div className="hero-banner__container">
              <div className="hero-banner_container-left">
                <img src={MobileHomeOne} alt="" className="mobile-home-one"/>
                <img src={herobannerImage} alt="" className="phone-img" />
                <img src={herobannerSubImage} alt="" className="sub-hero-image" />
              </div>
              <div className="hero-banner_container-right px-75">
                <h1 className="hero-main__title">The all-in-one smart</h1>
                <h1 className="hero-main__title hero-bold primary-color">
                  LOYALTY SOLUTION
                </h1>
                <h1 className="hero-main__title">to grow your business</h1>
                <div className="hero-paragraph">
                PayTack is a loyalty platform that connects customers to our network of loyalty partners to earn cashback that can be used across our partners, increasing repeat purchases.
                </div>
                <button
                  type="button"
                  onClick={partnerModal}
                  className="btn-primary">Join Now</button>
              </div>
            </div>
          </div>

          <div className="hero-banner__slick">
            <div className="hero-banner__container">
              <Fade top>
                <div className="hero-banner_container-left">
                  <img src={Group7455} alt="" className="phone-img" />
                  <img src={MobileHomeTwo} alt="" className="mobile-home-two"/>
                </div>
                <div className="hero-banner_container-right px-75">
                  <h1 className="hero-main__title">
                    Earning cashback is made <span className="hero-main__title hero-bold primary-color">SIMPLE</span> and <span className="hero-main__title hero-bold primary-color">AUTOMATIC</span>
                  </h1>
                  <div className="hero-paragraph py-25">
                    Make a purchase at our partner store, receive cashback benefits directly to your bank account and redeem rewards anytime anywhere.
                  </div>
                  <div className="btn-group__flex my-25">
                    <a href="#business" className="btn-primary">Explore</a>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </HeroBannerSlick>
        <PartnerModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
      </section>
    </Slide>
  );
};

export default HeroBanner;
