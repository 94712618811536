import React, { useState } from "react";
// import { Link } from "react-router-dom";
import Logo from "../../images/greenLogo.png";
import DkFlag from "../../images/footer-images/dk.png";
import SeFlag from "../../images/footer-images/sweden-flag.png";
// import CirclefBg from "../../images/footer-images/greyshape.svg";
// import styled from "styled-components";
import "../../styles/footer.css";
import Subcribtion from "../Home/Subcribtion";

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaGithub } from "react-icons/fa";
import PartnerModal from "../Home/PartnerModal";

const Footer = (props) => {
  const { handleClickFAQ } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false)
 
  const  partnerModal = () => {
    document.body.style.overflow = 'hidden';
    setModalIsOpen(true);
  }
  const  closeModal = () => {
    document.body.style.overflow = 'auto';
    setModalIsOpen(false);
  }

  return (
    <>
    {/* Commenting below section for subscribe to Newsletter */}
      {/* <Subcribtion /> */}
      <section className="footer-container">
        <div className="footer-container-section">
          <div className="container">
            <Zoom>
            <div className="footer-container__section company-logo">
              <img id="footerLogo" src={Logo} alt="" className="mb-3" />
              <p className="loyaltyCompany">A digital loyalty company</p>

              <div className="company-social__media">
                <a target="_blank"
                  className="company-social__media-link"
                  href="https://www.facebook.com/paytack/" rel="noreferrer"
                >
                  <FaFacebookF />
                </a>

                <a
                  className="company-social__media-link"
                  href="https://twitter.com/TackPay?s=20" target="_blank"
                >
                  <FaTwitter />
                </a>
                <a target="_blank"
                  className="company-social__media-link"
                  href="https://www.linkedin.com/company/paytack"
                >
                  <FaLinkedinIn />
                </a>
                <a target="_blank"
                  className="company-social__media-link"
                  href="https://www.instagram.com/paytack_loyalty/"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
            </Zoom>
            <Fade left>
              <div>
            {/* <h3 className="head-secondary" >Find Us</h3> */}
            <div className="footer-container__section company-address">
              <div className="company-address__left">
                <div className="flag-title">
                  <h6 className="country-name"> Sweden</h6>
                  <img src={SeFlag} alt="dk-flag" className="flag" />
                </div>
                <p className="address-field">PayTack AB </p>
                <p className="address-field">
                  Anckargripsgatan 3, <br />
                  211 19 Malmö
                </p>
                <p className="address-mail">info@paytack.se</p>
              </div>

              <div className="company-address__right">
                <div className="flag-title">
                  <h6 className="country-name"> Denmark</h6>
                  <img src={DkFlag} alt="dk-flag" className="flag" />
                </div>
                <p className="address-field">PayTack ApS </p>
                <p className="address-field">
                  Højbro Pl. 10, <br />
                  1200 København K
                </p>
                <p className="address-field">CVR: 40209751</p>
                <p className="address-mail">info@paytack.se</p>
              </div>
            </div>
            </div>
            </Fade>

          <Fade right>
            <div className="footer-container__section navigation-links">
            {/* <h3 className="head-secondary" >Information</h3> */}
              {/* <li className="nav-item">
                <Link className="footerLink" to="/aboutus" onClick={()=> window.scrollTo(0, 0)}>
                  About us
                </Link>
              </li> */}
              <li className=" nav-item">
                <Link className="footerLink" onClick={partnerModal}>
                  Become a Partner
                </Link>
              </li>
              <li className=" nav-item">
                <Link className="footerLink " to="/privacypolicy" target="_blank">
                  Privacy Policy & GDPR
                </Link>
              </li>
              <li className=" nav-item">
                <Link className="footerLink" to="/termsconditions" target="_blank">
                  Terms & Conditions
                </Link>
              </li>
              {/* <li onClick={handleClickFAQ} className="faqLink nav-item">
                <a className="footerLink" href="/faq">
                  FAQ {">"}
                </a>
              </li> */}
            </div>
            </Fade>

            {/* <div className="footer-container__section app-details">
              <div className="app-details__btns">
                <a
                  className="share__link"
                  href="https://apps.apple.com/us/app/paytack/id1502442601?ls=1"
                >
                  <div className="app-details__btn apple-share"></div>
                </a>{" "}
                <br />
                <a
                  className="share__link"
                  href="https://play.google.com/store/apps/details?id=com.paytack.application&hl=en_US"
                >
                  <div className="app-details__btn playstore-share"></div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <PartnerModal modalIsOpen={modalIsOpen} closeModal={closeModal}/>
    </>
  );
};
export default Footer;
