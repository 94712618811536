import React from "react";
import Faq from "../components/Home/FAQ";

const FaqPage = () => {
  return (
    <>
      <Faq />
    </>
  );
};

export default FaqPage;
