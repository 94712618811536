import React from "react";
import headIcon from "../../images/home/how-it-works-images/logo_element.svg";
import HowItsWorksOne from "../../images/howitworks/howItWorksOne.png";
import HowItsWorksTwo from "../../images/howitworks/howItWorkstwo.png";
import HowItsWorksThree from "../../images/howitworks/howItWorksThree.png";
import OneTopLeft from "../../images/howitworks/oneTopLeft.png";
import OneBottomLeft from "../../images/howitworks/oneBottomLeft.png";
import TwoBottomLeft from "../../images/howitworks/twoBottomLeft.png";
import TwoBottomRight from "../../images/howitworks/twoBottomRight.svg";
import TwoTopLeft from "../../images/howitworks/twoTopLeft.png";
import ThreeBottomRight from "../../images/howitworks/threeBottomRight.png";
import ThreeMiddleRight from "../../images/howitworks/threeMiddleRight.png";
import ThreeTopLeft from "../../images/howitworks/threeTopLeft.png";
import ThreeTopRight from "../../images/howitworks/threeTopRight.png";
import OneTwoConnect from "../../images/howitworks/oneTwoConnect.png";
import TwoThreeConnect from "../../images/howitworks/twoThreeConnect.png";
import '../../styles/howItWorks.css'


import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";

const HowItWorks = () => {
  return (
    <div className="how-it-works-section" id="howits">
      <section className="container how-it-works pb-0" style={{width:'80%'}}>
        <Zoom>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={headIcon}
              alt="bubble"
            />
            <h1 className="container-heading__title">How it works?</h1>
          </div>
        </Zoom>
        <div className="how-it-works-container-body">
          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto', marginTop:'50px' }}>1</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'20px'}}>Sign-up</h2>
            </div>}
            <Zoom>
              <div className="how-it-works-left-container">
                <div className="how-it-works-image-fit-container">
                  <img
                    className="how-it-works-image-fit"
                    src={HowItsWorksOne}
                    alt="how-it-works"
                  />
                  <img
                    className="one-top-left"
                    src={OneTopLeft}
                    alt="how-it-works"
                  />
                  <img
                    className="one-bottom-left"
                    src={OneBottomLeft}
                    alt="how-it-works"
                  />
                  <img
                    className="one-two-connect"
                    src={OneTwoConnect}
                    alt="how-it-works"
                  />
                </div>
                <Bounce>
                  <div className="how-it-works-description">
                    {window.innerWidth >= 700 && <h2 className="how-it-works-description-heading">Sign-up</h2>}
                    <div className="text-conatiner">
                      {window.innerWidth >= 700 && <div className="number-indicator">1</div>}
                      <p>
                        Your customer downloads and signs up to the PayTack App, connect their account and explore offers by your business.
                    </p>
                    </div>
                  </div>
                </Bounce>
              </div>
            </Zoom>
          </div>

          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto' , marginTop:'50px' }}>2</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'20px'}}>Upload the bill</h2>
            </div>}
            <Zoom>
              <div className="how-it-works-middle-container">
                <div className="how-it-works-image-fit-container">
                  <img
                    className="how-it-works-image-fit"
                    src={HowItsWorksTwo}
                    alt="how-it-works"
                  />
                  <img
                    className="two-top-left"
                    src={TwoTopLeft}
                    alt="how-it-works"
                  />
                  <img
                    className="two-bottom-left"
                    src={TwoBottomLeft}
                    alt="how-it-works"
                  />
                  <img
                    className="two-bottom-right"
                    src={TwoBottomRight}
                    alt="how-it-works"
                  />
                  <img
                    className="two-three-connect"
                    src={TwoThreeConnect}
                    alt="how-it-works"
                  />
                </div>
                <Bounce bottom>
                  <div className="how-it-works-description">
                    {window.innerWidth >= 700 && <h2 className="how-it-works-description-heading">Upload the bill</h2>}
                    <div className="text-conatiner">
                      {window.innerWidth >= 700 && <div className="number-indicator">2</div>}
                      <p>
                        After purchase, your customer uploads the receipt to our app and avail cashback directly to their bank account.
                  </p>
                    </div>
                  </div>
                </Bounce>
              </div>
            </Zoom>
          </div>

          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto', marginTop:'50px'  }}>3</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'100px'}}>Earn Cashback</h2>
            </div>}
            <Zoom>
              <div className="how-it-works-right-container">
                <div className="how-it-works-image-fit-container">
                  <img
                    className="how-it-works-image-fit"
                    src={HowItsWorksThree}
                    alt="how-it-works"
                  />
                  <img
                    className="three-top-left"
                    src={ThreeTopLeft}
                    alt="how-it-works"
                  />
                  <img
                    className="three-bottom-right"
                    src={ThreeBottomRight}
                    alt="how-it-works"
                  />
                  <img
                    className="three-middle-right"
                    src={ThreeMiddleRight}
                    alt="how-it-works"
                  />
                  <img
                    className="three-top-right"
                    src={ThreeTopRight}
                    alt="how-it-works"
                  />
                </div>
                <Bounce right>
                  <div className="how-it-works-description">
                    {window.innerWidth >= 700 && <h2 className="how-it-works-description-heading">Earn Cashback</h2>}
                    <div className="text-conatiner">
                      {window.innerWidth >= 700 && <div className="number-indicator">3</div>}
                      <p>
                        The loyalty cashback or rewards balance will be made visible to your customer, consistent with your reward programme.
                  </p>
                    </div>
                  </div>
                </Bounce>
              </div>
            </Zoom>

          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
