import { FaRegTimesCircle } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import mobileLogo from "../../images/mobileLogo.png";
import logo from "../../images/newlogo.png";

import { Link as scrollNavLink } from "react-scroll";

const SidebarContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #2bc5b4;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? "1" : "0")};
  top: ${({ isMenuOpen }) => (isMenuOpen ? "0px" : "-100%")};
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
// const SidebarWrapper = styled.div``;
const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  height: 80vh;
`;

const SidebarMenuLink = styled(scrollNavLink)`
  display: block;
  padding: 5px 0;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 3rem;

  &:after {
    content: "";
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &.active {
    // border-bottom: solid 3px #019fb6;

    &:after {
      content: "";
      display: block;
      transform: scaleX(0.3);
      transition: transform 250ms ease-in-out;
    }
    &:hover:after {
      transform: scaleX(1);
    }
  }
`;

const SidebarMenuLink2 = styled(Link)`
  display: block;
  padding: 5px 0;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 3rem;

  &:after {
    content: "";
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &.active {
    &:after {
      content: "";
      display: block;
      transform: scaleX(0.3);
      transition: transform 250ms ease-in-out;
    }
    &:hover:after {
      transform: scaleX(1);
    }
  }
`;

const CloseMenu = styled.div`
  top: 20px;
  right: 20px;
  position: absolute;
  font-size: 31px;
  color: #fff;
`;
const Logo = styled(Link)`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${logo});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 20px;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }

    .logo_img {
      background-image: url(${mobileLogo}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 30px;
    }
  }
`;

const ActiveTabStyle = styled.div`
  position: absolute;
  width: 30px;
  height: 3px;
  bottom: 8px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
`;

const Sidebar = ({ isMenuOpen, menuToggle, linksData }) => {
  return (
    <>
      <SidebarContainer isMenuOpen={isMenuOpen} onClick={menuToggle}>
        <Logo to="/">
          <div className="logo_img"></div>
        </Logo>
        <SidebarMenu>
          <CloseMenu onClick={menuToggle}>
            <FaRegTimesCircle />
          </CloseMenu>
          {linksData[0].id === "home" ? (
            <SidebarMenuLink
              activeClass="active"
              smooth={true}
              offset={-75}
              duration={100}
              onClick={menuToggle}
              to={linksData[1].link}
            >
              {linksData[1].title}
              {window.location.pathname === "/" && <ActiveTabStyle />}
            </SidebarMenuLink>
          ) : (
            <SidebarMenuLink2 onClick={menuToggle} to={linksData[1].link}>
              {linksData[1].title}
              {window.location.pathname === "/" && <ActiveTabStyle />}
            </SidebarMenuLink2>
          )}

          {/* <SidebarMenuLink2 onClick={menuToggle} to={linksData[2].link}>
            {linksData[2].title}
            {window.location.pathname === '/aboutus' && <ActiveTabStyle />}
          </SidebarMenuLink2> */}
          {linksData[0].id === "home" ? (
            <SidebarMenuLink
              activeClass="active"
              smooth={true}
              offset={-75}
              duration={100}
              onClick={menuToggle}
              to={linksData[3].link}
            >
              {linksData[3].title}
              {window.location.pathname === "/contactus" && <ActiveTabStyle />}
            </SidebarMenuLink>
          ) : (
            <SidebarMenuLink2 onClick={menuToggle} to={linksData[3].link}>
              {linksData[3].title}
              {window.location.pathname === "/contactus" && <ActiveTabStyle />}
            </SidebarMenuLink2>
          )}
        </SidebarMenu>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
