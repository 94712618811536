import React, { Component } from "react";
import LogoElement from "../../images/home/customers/logo-element-orange.svg";
import contactImageBg from "../../images/home/customers/contactus-bg.svg";

// import Modal from "react-bootstrap/Modal";

import styled from "styled-components";
import Pulse from "react-reveal/Pulse";
import Bounce from "react-reveal/Bounce";

import emailjs from "emailjs-com";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Button = styled.button``;

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      companyName: "",
      email: "",
      message: "",
    };
    this.sendEmail = this.sendEmail.bind(this);
  }
  state = {
    modalShow: false,
    errorMessage: false,
  };

  setInput = (key, value) => {
    this.setState({ ...this.state.formState, [key]: value });
  };

  sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_atczn91",
        "contact_template",
        e.target,
        "user_9pgsyj7HLOdLT0DqXNm2R"
      )
      .then(
        (result) => {
          toast.success(
            "You are successfully submitted. We will contact you!!!",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        },
        (error) => {
          toast.error("You are not subscribed. Please submit again!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    e.target.reset();
  }

  render() {
    const {isMenuOpen} = this.state
    return (
      <section className="container contact-us" id="contactus">
        <Pulse>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={LogoElement}
              alt="bubble"
            />
            <h1 className="container-heading__title">Want to know more?</h1>
            <p>
              Leave your details in the form below and we will contact you
              shortly
            </p>
          </div>
        </Pulse>
        <div className="container-body">
          <div className="container-body__from grid-order__change py-25">
            <Bounce left>
              <form
                onSubmit={this.sendEmail}
                className="container-body__from-sectoin"
              >
                <label className="form-label">
                  First Name <span className="required-asterisk">*</span>
                </label>
                <input
                  className="inputField"
                  name="first_name"
                  type="text"
                  placeholder=""
                  required
                />
                <label className="form-label">Company Name <span className="required-asterisk">*</span></label>
                <input
                  className="inputField"
                  name="company_name"
                  type="text"
                  placeholder=""
                  required
                />
                <label className="form-label">
                  Email <span className="required-asterisk">*</span>
                </label>
                <input
                  className="inputField"
                  type="email"
                  name="email"
                  placeholder=""
                  required
                />
                <label className="form-label">Message <span className="required-asterisk">*</span></label>
                <textarea
                  className="inputField"
                  name="message"
                  cols="30"
                  rows="10"
                  placeholder=""
                  required
                ></textarea>
                <div className="form-button">
                  <Button
                    className=" form-submit btn-primary"
                    type="submit"
                    value="Send"
                  >
                    Send
                  </Button>
                </div>
              </form>
            </Bounce>
            <Bounce right>
              <div className="contactImage">
                <img src={contactImageBg} alt="" />
              </div>
            </Bounce>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </section>
    );
  }
}

export default ContactUs;
