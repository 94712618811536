import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

import home from "./pages/Home";
import loyalty from "./pages/Loyalty";
import faqPage from "./pages/FaqPage";

import Footer from "./components/Footer/Footer";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import Partner from "./pages/Partner";
import Business from "./pages/Business";
import Terms from "./pages/Terms";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="PayTackApp">
      <Router>
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path="/home" component={home} />
          <Route exact path="/loyalty" component={loyalty} />
          <Route exact path="/faq" component={faqPage} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/contactus" component={ContactUs} />
          <Route exact path="/becomepartner" component={Partner} />
          <Route path="/business" component={Business} />
          <Route exact path="/termsconditions" component={Terms} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
