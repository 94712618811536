import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import hambugerIcon from "../../images/hamburger.svg";
import logo from "../../images/newlogo.png";
import mobileLogo from "../../images/mobileLogo.png";
import greenLogo from "../../images/greenLogo.png";

import LanguageSwitcher from "./LanguageMenu";
import { Link as scrollNavLink } from "react-scroll";

import "../../styles/Navbar.css";

const Nav = styled.nav`
  // background-color: #2bc5b4;
`;
const MobileMenuBar = styled.i`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    background-image: url(${hambugerIcon});
    width: 50px;
    background-repeat: no-repeat;
    height: 50px;
    cursor: pointer;
  }
`;
const Logo = styled(Link)`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${logo});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
    .logo_img {
      background-image: url(${mobileLogo}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
    }
  }
`;
const LogoDiv = styled.div`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${logo});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
    .logo_img {
      background-image: url(${mobileLogo}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
    }
  }
`;
const NavMenu = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
const NavItems = styled(scrollNavLink)`
position:relative;
  margin: 5px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: ${({ scrollNav }) => (scrollNav ? "#fff" : "#000")};
  cursor:pointer;

  &:after {
    content: "";
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  // &:hover:after {
  //   transform: scaleX(1);
  // }

  &.active {
    font-weight:600

    &:after {
      content: "";
      display: block;
      transform: scaleX(0.5);
      transition: transform 250ms ease-in-out;
    }
    // &:hover:after {
    //   transform: scaleX(1);
    // }
  }
`;

const NavLinkItem = styled(Link)`
position:relative;
  margin: 5px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: ${({ scrollNav }) => (scrollNav ? "#fff" : "#000")};

  &:after {
    content: "";
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  // &:hover:after {
  //   transform: scaleX(1);
  // }

  &.active {
    font-weight:600

    &:after {
      content: "";
      display: block;
      transform: scaleX(0.5);
      transition: transform 250ms ease-in-out;
    }
    // &:hover:after {
    //   transform: scaleX(1);
    // }
  }
`;
const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Header = styled.header`
  background: ${({ scrollNav }) => (scrollNav ? "#2dcdbc" : "transparent")};
  position: fixed;
  z-index: 10;
  width: 100%;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const NavMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveTabStyle = styled.div`
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: ${({ scrollNav }) => (scrollNav ? "#fff" : "#000")};
  left: 50%;
  transform: translateX(-50%);
`;

function Navbar({ menuToggle, isMenuOpen, linksData }) {
  const [scrollNav, setScrollNav] = useState(false);
  const [isScrollNavLink, setIsScrollNavLink] = useState(true);
  const navScrollActive = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
      document.querySelector(".top-nav") &&
        document.querySelector(".top-nav").classList.add("active-top");
    } else {
      setScrollNav(false);
    }
  };

  let loyaltyClass;

  console.log("scrollNav", scrollNav);

  useEffect(() => {
    window.addEventListener("scroll", navScrollActive);
  }, []);
  return (
    <>
      <Container className={loyaltyClass}>
        <Header scrollNav={scrollNav} className="top-nav active-top">
          <Nav className="logo nav-bar">
            {/* <Logo to='/'>
              <div className="logo_img"></div>
            </Logo> */}
            {linksData[0].id !== "home" ? (
              <Logo to="/">
                <div className="logo_img"></div>
              </Logo>
            ) : (
              <LogoDiv>
                <div className="logo_img"></div>
              </LogoDiv>
            )}

            <NavMenuContainer>
              <NavMenu>
                {linksData[0].id === "home" ? (
                  <NavItems
                    activeClass="active"
                    scrollNav={scrollNav}
                    smooth={true}
                    offset={-60}
                    to="business"
                    duration={100}
                  >
                    For Business
                    {window.location.pathname === "/" && (
                      <ActiveTabStyle scrollNav={scrollNav} />
                    )}
                  </NavItems>
                ) : (
                  <NavLinkItem
                    smooth={true}
                    offset={0}
                    scrollNav={scrollNav}
                    to="/"
                    className="menu-links-other"
                  >
                    For Business
                    {window.location.pathname === "/" && (
                      <ActiveTabStyle scrollNav={scrollNav} />
                    )}
                  </NavLinkItem>
                )}

                {/* <NavItems to="/loyalty">Loyalty App</NavItems> */}
                {/* <NavLinkItem
                  className="menu-links-other"
                  smooth={true}
                  scrollNav={scrollNav}
                  onClick={() => setIsScrollNavLink(true)}
                  offset={0}
                  to="/aboutus"
                >
                  About Us
                  {window.location.pathname === "/aboutus" && (
                    <ActiveTabStyle scrollNav={scrollNav} />
                  )}
                </NavLinkItem> */}
                {linksData[0].id === "home" ? (
                  <NavItems
                    activeClass="active"
                    smooth={true}
                    offset={-60}
                    scrollNav={scrollNav}
                    to="contactus"
                    duration={100}
                  >
                    Contact
                    {window.location.pathname === "/contactus" && (
                      <ActiveTabStyle scrollNav={scrollNav} />
                    )}
                  </NavItems>
                ) : (
                  <NavLinkItem
                    className="menu-links-other"
                    smooth={true}
                    offset={0}
                    scrollNav={scrollNav}
                    to="/contactus"
                  >
                    Contact
                    {window.location.pathname === "/contactus" && (
                      <ActiveTabStyle scrollNav={scrollNav} />
                    )}
                  </NavLinkItem>
                )}
              </NavMenu>
              {/* <LanguageSwitcher /> */}
              <MobileMenuBar onClick={menuToggle} />
            </NavMenuContainer>
          </Nav>
        </Header>
      </Container>
    </>
  );
}

export default Navbar;
