import React, { useState } from "react";

import Contact from "../components/Home/Contact";
import Navbar from "../components/Navbar/Navbar";
import { menuDataOther } from "../data/MenuData";

const ContactUs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <Navbar linksData={menuDataOther} menuToggle={menuToggle} />
      <div className="contact-us head-bg p-100">
        <Contact />
      </div>
    </div>
  );
};

export default ContactUs;
