import React from "react"

import LogoElement from "../images/home/benefits-images/rectangle-30.svg"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import logo from "../images/newlogo.png"
import mobileLogo from "../images/mobileLogo.png"
import { Link } from "react-router-dom"

const Logo = styled(Link)`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${logo});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -50px;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
    .logo_img {
      background-image: url(${mobileLogo}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
      margin-top: -70px;
    }
  }
`

const GoToTop = styled.div`
  position: absolute;
  bottom: -70px;
  right: 25px;
  padding: 0 30px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const PrivacyPolicy = () => {
  return (
    <div className="head-bg">
      <section className="container privacy pt-100">
        <Logo to="/">
          <div className="logo_img"></div>
        </Logo>
        <Zoom>
          <div className="container-heading container-heading-responsive">
            <img
              className="container-heading__image"
              src={LogoElement}
              alt="bubble"
            />
            <h1 className="container-heading__sub-title primary-color">
              PRIVACY POLICY AND GDPR
            </h1>
          </div>
        </Zoom>
        <div className="container-body container-body__responsive" style={{ position: "relative" }}>
          <GoToTop onClick={() => window.scrollTo(0, 0)}>Go To Top</GoToTop>
          <Fade bottom>
            <div className="pt-100 px-100">
              <h1 className="container-heading__sub-header primary-color">
                1.	INTRODUCTION
              </h1>
              <p>
                Your privacy and the confidentiality of your personal data is very important to us at PayTack. This Privacy Policy details the collection, use, sharing and storage of your personal data.
              </p>
              <h1 className="container-heading__sub-header primary-color">
                2. DEFINITIONS
              </h1>
              <p>
                Terms in this Privacy Policy shall have the same meaning as defined in the Terms and conditions.
              </p>
              <h1 className="container-heading__sub-header primary-color">
                3.	DATA COLLECTED BY PAYTACK
              </h1>
              <p>
                To create an Account, you need to provide data including your name, email address country and city or Zip code, and a password (four digits). Your first name and city are only made available to the Merchant that you are subscribed for collecting cashback. The collection and sharing of your data is necessary for performance of the contract you have entered into by accepting the Terms and conditions
              </p>
              <h1 className="container-heading__sub-header primary-color">
                4. BANK LINK
              </h1>
              <p>
                Your bank is linked for the purpose of processing purchase transactions that involves your purchase made at our partners or merchants offering cashback on our app. 
                <br />
                <strong>
                Please note that it is entirely voluntary to consent to bank link – the choice is yours. You are free to withdraw your consent at any time. You are also free to delete your Account at any time and thus, delete any and all linked banks
                </strong>
                <br/>
                Bank Linked is processed using Open Api banking technology as a part of PSD2 license which is supervised by Danish Financial supervisory authority(DFSA).
                <br />
                PayTack uses Tink AB Open banking api for the processing of user’s banking data. Tink AB acts as a data controller and processes the data with it’s services via product name Tink Link and transactional data via product nameTransaction.
                <br />
                 Paytack is data processor after we receive your bank data from Tink AB
              </p>
              <h1 className="container-heading__sub-header primary-color">
                5. HOW YOUR DATA IS SHARED
              </h1>
              <p>
                PayTack is the data controller for making available of personal data to Merchant that the User has subscribed to. No data are made available or disclosed by transmission to any Merchant the User has not subscribed to.
                <br />
                The recipient Merchant is the data controller for processing of received data. Please read the recipient’s Merchant Privacy Policy carefully.
                                <br />
                PayTack will process and share your data if necessary, for the establishment, exercise or defence of legal claims or if necessary for complying with a court order.
              </p>
              <h1 className="container-heading__sub-header primary-color">
                6.	YOUR RIGHTS
              </h1>
              <ul>
                <li>
                  <strong>Delete Data:{" "}</strong>
                  You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you). You are at all times free to delete your Account and thereby deleting all your data.
                </li>
                <li>
                  <strong>Change or Correct Data:{" "}</strong>
                  You can edit some of your personal data through your Account. You can also ask us to change, update or fix your data in certain cases, particularly if it is inaccurate.

                </li>
                <li>
                  <strong>Object to, or Limit or Restrict, Use of Data:{" "}</strong>
                  You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).
                </li>
                <li>
                  <strong>Right to Access and/or Take Your Data:{" "}</strong>
                  You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form. 
                </li>
              </ul>
              <p>
                Please note that, if we have reasonable doubts concerning the requesting person’s identity, we have the legal obligation to confirm said person’s identity before processing your request. 
                <br />
                You can exercise your rights by contacting support@paytack.com
              </p>
              <h1 className="container-heading__sub-header primary-color">
               7.	TRANSFER OF DATA TO A THIRD COUNTRY
              </h1>
              <p>
               PayTack aims to process and store all data within the European Union (“Union”) and the European Economic Area (“EEA”). If any data is transferred to a country outside the Union or EEA (“third country”) such transfer will be subject to suitable safeguards such as the European Commission’s standard contractual clauses for export of data to a data processor (C(2010)593). 
              </p>
              <h1 className="container-heading__sub-header primary-color">
                8.	THE RIGHT TO LODGE A COMPLAINT
              </h1>
              <p>
                If you have a dispute with us, please contact us and we will aim to resolve the matter quickly.PayTack is a Danish entity and thus is under the supervision of the Danish Data Protection Authority Datatilsynet.
                <br />
                You have the right to complain about Cashback's processing of your personal data. Such a complaint
                can be submitted to the Danish Data Protection Agency, Borgergade 28, 5., 1300 Copenhagen K, www.datatilsynet.dk .
              </p>
              <h1 className="container-heading__sub-header primary-color">
                9.	CHANGES TO THIS PRIVACY POLICY
              </h1>
              <p>
                PayTack can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may delete your Account. 
                <br />
                You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy.
              </p>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  )
}

export default PrivacyPolicy
